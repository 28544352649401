import { IonCard, IonCardContent, IonCardHeader, IonCardSubtitle } from '@ionic/react';
import styled from 'styled-components';

const StyledIonCard = styled(IonCard)`
  --background: rgba(var(--figma-color-ocean-8-rgb), 0.08);

  margin: 0;
  box-shadow: none;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const StyledIonCardHeader = styled(IonCardHeader)`
  padding-bottom: 0.5rem;
`;

const StyledIonCardSubtitle = styled(IonCardSubtitle)`
  --color: var(--figma-color-ocean-300);

  ion-icon {
    font-size: 1.5rem;
  }
`;

const StyledIonCardContent = styled(IonCardContent)`
  color: var(--figma-color-ocean-400);
  font-weight: normal;
`;

export default {
  IonCard: StyledIonCard,
  IonCardHeader: StyledIonCardHeader,
  IonCardSubtitle: StyledIonCardSubtitle,
  IonCardContent: StyledIonCardContent,
};
