import React, { useMemo } from 'react';

import PropertyCard from './PropertyCard';
import PropertyCardAddress from './PropertyCardAddress';
import S from './PropertyDetails.styles';
import { Property } from '../../types/property';
import { numberToSqFt, numberToUSD, toNumberOrNull } from '../../utils/number';
import { capitalizeFirstLetter } from '../../utils/text';

interface Props {
  property?: Property
  isLoading?: boolean
}

const PropertyDetails: React.FC<Props> = ({
  property,
  isLoading
}) => {
  const propertyType = useMemo(() => {
    const landUseValue = property?.precisely.propertyAttributes.landUse?.value;

    if (!landUseValue) return null;

    return capitalizeFirstLetter(landUseValue);
  }, [property]);

  return (
    <S.CardsGrid>
      <PropertyCardAddress property={property} isLoading={isLoading} isShowingImage />

      <PropertyCard title="Property Details" isLoading={isLoading} loadingLines={3}>
        {!!propertyType && <>
          {propertyType}
          <br />
        </>
        }

        {toNumberOrNull(property?.precisely.propertyAttributes?.bedrooms) || 'N/A'} Bedroom · {toNumberOrNull(property?.precisely.propertyAttributes?.baths) || 'N/A'} Bath
        <br />
        Built in {property?.precisely.propertyAttributes?.builtYear || 'N/A'}
      </PropertyCard>

      <PropertyCard title="Building Size" isLoading={isLoading}>
        {numberToSqFt(property?.building_sq_ft) ?? 'Unkown'}
      </PropertyCard>

      <PropertyCard title="Property Size" isLoading={isLoading}>
        {numberToSqFt(property?.property_sq_ft) ?? 'Unkown'}
      </PropertyCard>

      <PropertyCard title="Last Sold Price" isLoading={isLoading}>
        {numberToUSD(Number(property?.precisely.propertyAttributes?.taxSalesPrice)) ?? numberToUSD(Number(property?.precisely.propertyAttributes?.priorSalesPrice))}
      </PropertyCard>

      <PropertyCard title="Tax by Year" isLoading={isLoading}>
        {numberToUSD(Number(property?.precisely.propertyAttributes?.taxAmount)) ?? 'Unkown'}
      </PropertyCard>
    </S.CardsGrid>
  );
};

export default PropertyDetails;
