import { environment } from '@env';
import { IonApp, IonPage, setupIonicReact } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { PostHogProvider } from 'posthog-js/react';
import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import SideMenu from './components/SideMenu';
import { Routes } from './config/routes';
import AuthProvider from './context/AuthProvider';
import AboutUs from './pages/AboutUs';
import Chat from './pages/Chat';
import DashboardPage from './pages/DashboardPage';
import Home from './pages/Home';
import Login from './pages/Login';
import PrivacyPolicy from './pages/PrivacyPolicy';
import PropertyCreatePage from './pages/PropertyCreatePage';
import TermsOfService from './pages/TermsOfService';
/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';
/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/display.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/padding.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';

/* Global Styles */
import './styles.scss';
import './theme/typography.scss';

/* Ionic components */
import './theme/components.scss';

/* Theme variables */
import './theme/variables-figma.scss';
import './theme/variables.scss';

const options = {
  api_host: environment.posthogUrl,
};

setupIonicReact();

const queryClient = new QueryClient();

const App: React.FC = () => (
  <PostHogProvider apiKey={environment.posthogKey} options={options}>
    <QueryClientProvider client={queryClient}>
      <IonApp>
        <IonReactRouter>
          <AuthProvider>
            <SideMenu />

            <IonPage id="main-content">
              <Switch>
                <Route exact path={Routes.home} component={Home} />
                <Route exact path={Routes.login} component={Login} />
                <Route exact path={Routes.signup} component={Login} />
                <Route exact path={Routes.dashboard} component={DashboardPage} />
                <Route exact path={Routes.properties}>
                  <Redirect to={Routes.dashboard} />
                </Route>
                <Route exact path={Routes.createProperty} component={PropertyCreatePage} />
                <Route exact path={Routes.chat} component={Chat} />
                <Route exact path={Routes.aboutUs} component={AboutUs} />
                <Route exact path={Routes.privacyPolicy} component={PrivacyPolicy} />
                <Route exact path={Routes.termsOfService} component={TermsOfService} />
                <Route>
                  <Redirect to={Routes.home} />
                </Route>
              </Switch>
            </IonPage>
          </AuthProvider>
        </IonReactRouter>
      </IonApp>
    </QueryClientProvider>
  </PostHogProvider>
);

export default App;
