import styled from 'styled-components';

const ChatWrapper = styled.div`
  margin-top: 5rem;
  margin-bottom: 5rem;
`;


const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 0.5rem;
`;

export default {
  ChatWrapper,
  ButtonsWrapper,
};
