import { IonButton } from '@ionic/react';
import styled from 'styled-components';

const Component = styled.div`
  border-radius: var(--figma-border-radius-button);
  background: var(--figma-color-neutral-100);
  width: 100%;
  position: relative;
`;

const Input = styled.input`
  border-radius: var(--figma-border-radius-button);
  border: none;
  background: var(--figma-color-neutral-100);
  font-size: 1rem;
  padding: 1rem 3rem 1rem 1.25rem;
  width: 100%;
  outline-color: var(--figma-color-darkgray-300);
  color: var(--ion-color-dark);
`;

const Button = styled(IonButton)`
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
`;

export default {
  Component,
  Input,
  Button,
};
