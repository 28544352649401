import { capitalizeFirstLetter } from './text';

export interface Address {
  street?: string;
  city?: string;
  state?: string;
  county?: string;
  zipcode?: string;
  country?: string;
  user_id?: string;
}

export const DEFAULT_ADDRESS: Address = {
  street: '1 Monument Dr',
  city: 'Princeton',
  state: 'New Jersey',
  county: 'Mercer County',
  zipcode: '08540',
  country: 'United States',
};

export const addressToText = (address: Address): string => [
  capitalizeFirstLetter(address.street),
  capitalizeFirstLetter(address.city),
  address.state,
  capitalizeFirstLetter(address.zipcode)
]
  .filter(v => !!v)
  .join(', ');

export const getIsAddressEnabled = (address: Address): boolean => true;

export const getIsDefaultAddress = (address: Address): boolean => (Object.keys(DEFAULT_ADDRESS) as (keyof Address)[]).every(
  (key) => address[key] === DEFAULT_ADDRESS[key]
);
