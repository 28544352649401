import { IonButton, IonCard, IonCardContent, IonCardHeader, IonCardSubtitle } from '@ionic/react';

import { Routes } from '../../config/routes';

const CreateAccountPrompt: React.FC = () => {
  return (
    <IonCard>
      <IonCardHeader>
        <IonCardSubtitle>Create account</IonCardSubtitle>
      </IonCardHeader>

      <IonCardContent>
        Build your property profile and get instant, personalized home ownership answers.
      </IonCardContent>
      <IonCardContent>
        <IonButton routerLink={Routes.signup} color="darkgray" size="large" expand="block">
          Sign up
        </IonButton>
      </IonCardContent>
    </IonCard>
  );
};

export default CreateAccountPrompt;
