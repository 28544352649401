import { useCallback } from 'react';
import { DefaultGenerics } from 'stream-chat';
import { Channel as StreamChannel } from 'stream-chat/dist/types/channel';

import LLMChatBubble from '../LLMChatBubble';
import S from './StarterQuestions.styles';

const defaultQuestions = [
  'Where can I make my sewer bill payment?',
  'What are the bus routes in Princeton?',
  'What is the contact number for animal control?',
  'What are the average property taxes in Princeton?',
];

interface Props {
  questions?: string[];
  channel: StreamChannel<DefaultGenerics>;
}

const StarterQuestions: React.FC<Props> = ({ questions = defaultQuestions, channel }) => {
  const sendMessage = useCallback(
    async (text: string) => {
      try {
        await channel.sendMessage({
          text,
        });
      } catch (error) {
        console.error('Error sending message:', error);
      }
    },
    [channel]
  );

  return (
    <S.Wrapper>
      <LLMChatBubble>Here are some recently asked questions to get you started:</LLMChatBubble>

      {questions.map((question: string, index: number) => (
        <S.IonButton key={index} size="large" color="neutral" onClick={() => sendMessage(question)}>
          {question}
        </S.IonButton>
      ))}
    </S.Wrapper>
  );
};

export default StarterQuestions;
