export const numberToSqFt = (value?: number) => value && !Number.isNaN(value) ? `${value.toLocaleString()} sq ft` : null;

export const numberToUSD = (value?: number) => value && !Number.isNaN(value) ? `$${value.toLocaleString()}` : null;

export const toNumberOrNull = (value: any): number | null => {
  if (value === null || value === undefined) return null;

  const tryNumber = Number(value);

  if (Number.isNaN(tryNumber)) return null;

  return tryNumber;
};
