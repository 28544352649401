import { IonMenu, IonContent, IonMenuToggle } from '@ionic/react';
import React from 'react';

import Menu from './Menu';

import './SideMenu.scss';

const SideMenu: React.FC = () => (
  <IonMenu contentId="main-content">
    <IonContent className="ion-padding">
      <IonMenuToggle autoHide>
        <Menu orientation="vertical" />
      </IonMenuToggle>
    </IonContent>
  </IonMenu>
);

export default SideMenu;
