import { environment } from '@env';
import { IonCol, IonContent, IonGrid, IonPage, IonRow } from '@ionic/react';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import React, { useMemo } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import ContentGradient from '../components/ContentGradient';
import Header from '../components/Header';
import PropertyDetails from '../components/property/PropertyDetails';
import { Routes } from '../config/routes';
import { useAuth } from '../context/AuthProvider';
import { UserProperty } from '../types/userProperty';

export interface DashboardPageState {
  userProperty?: UserProperty
}

const DashboardPage: React.FC = () => {
  const { session } = useAuth();
  const history = useHistory();

  const location = useLocation<DashboardPageState>();

  const getUserPropertyQuery = useQuery({
    enabled: !!session?.access_token && !location.state?.userProperty?.Property,
    queryKey: ['dashboard', session?.access_token],
    queryFn: async () => {
      const response = await axios.get<UserProperty>(`${environment.api}/user-property`, {
        headers: {
          Authorization: `Bearer ${session?.access_token}`
        }
      });

      if (!response.data) history.replace(Routes.createProperty);

      return response.data;
    }
  });

  const property = useMemo(() => location.state?.userProperty?.Property || getUserPropertyQuery.data?.Property, [getUserPropertyQuery.data, location.state?.userProperty?.Property]);

  return (
    <IonPage>
      <IonContent>
        <ContentGradient />

        <IonGrid>
          <IonRow>
            <IonCol>
              <Header />
            </IonCol>
          </IonRow>
        </IonGrid>

        <IonGrid>
          <IonRow>
            <IonCol
              sizeXl="8"
              sizeLg="8"
              offsetXl="2"
              offsetLg="2"
              sizeMd="10"
              offsetMd="1"
              sizeSm="12"
            >
              <PropertyDetails
                property={property}
                isLoading={!property}
              />
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

export default DashboardPage;
