// ChatContext.tsx
import React from 'react';
import { Channel } from 'stream-chat';
// import { DefaultGenerics } from 'stream-chat/src/types';
// import { Channel } from 'stream-chat-react';

interface ChatContextType {
  channel: Channel<any> | null;
}

export const ChatContext = React.createContext<ChatContextType>({ channel: null });
