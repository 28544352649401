import { IonButton, IonCol, IonContent, IonGrid, IonPage, IonRow } from '@ionic/react';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';

import ContentGradient from '../components/ContentGradient';
import Header from '../components/Header';
import TerraiMessageInput from '../components/TerraiMessageInput';
import { Routes } from '../config/routes';
import { ChatRouteState } from './Chat';
import S from './Home.styles';

const topics = [
  {
    name: 'Waste',
    questions: [
      'When is my trash collection day?',
      'How do I dispose of large items or hazardous waste?',
    ],
  },
  {
    name: 'Recreation',
    questions: [
      'What are the operating hours for local parks?',
      'How do I reserve a pavilion for a family gathering?',
    ],
  },
  {
    name: 'Zoning & Property',
    questions: [
      'What are the zoning regulations for my property?',
      "How do I file a complaint about a neighboring property's condition?",
    ],
  },
  {
    name: 'Road & Transportation',
    questions: [
      "What's the schedule for snow plowing in my neighborhood?",
      'How do I report a pothole or damaged street sign?',
    ],
  },
  {
    name: 'Taxes',
    questions: ['When are property taxes due?', 'How can I view my property tax assessment?'],
  },
];

const Home: React.FC = () => {
  const intervalId = useRef<NodeJS.Timeout>();
  const [activeTopicName, setActiveTopicName] = useState(topics[0].name);
  const [question, setQuestion] = useState<string>('');
  const history = useHistory();

  const questions = useMemo(() => {
    return topics.find((topic) => topic.name === activeTopicName)?.questions ?? [];
  }, [activeTopicName]);

  useEffect(() => {
    intervalId.current = setInterval(() => {
      setActiveTopicName((_activeTopicName) => {
        const nextIndex =
          (topics.findIndex((topic) => _activeTopicName === topic.name) + 1) % topics.length;
        return topics[nextIndex].name;
      });
    }, 5000);

    return () => clearInterval(intervalId.current);
  }, []);

  const onTopicClick = (topicName: string) => {
    // User has interacted, so stop togglign between topics
    clearInterval(intervalId.current);
    setActiveTopicName(topicName);
  };

  const onQuestionClick = (question: string) => {
    // User has interacted, so stop togglign between topics
    clearInterval(intervalId.current);

    setQuestion(question);
    onQuestionSubmit(question);
  };

  const onQuestionSubmit = (question: string) => {
    history.push(Routes.chat, {
      question,
      isUnauthenticatedExperience: true,
    } as ChatRouteState);
  };

  return (
    <IonPage>
      <IonContent>
        <ContentGradient />

        <IonGrid>
          <IonRow>
            <IonCol>
              <Header />
            </IonCol>
          </IonRow>
        </IonGrid>

        <IonGrid>
          <IonRow>
            <IonCol
              sizeXl="6"
              sizeLg="8"
              offsetXl="3"
              offsetLg="2"
              sizeMd="10"
              offsetMd="1"
              sizeSm="12"
            >
              <S.Title>
                Get Instant Answers to Questions
                <br />
                About Your Home and Community.
                <br />
                Powered by AI.
              </S.Title>

              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  onQuestionSubmit(question);
                }}
              >
                <TerraiMessageInput value={question} onValueChange={setQuestion} />
              </form>
            </IonCol>
          </IonRow>
        </IonGrid>

        <IonGrid>
          <IonRow style={{ marginTop: 'auto' }}>
            <IonCol
              sizeXl="9"
              sizeLg="10"
              offsetXl="3"
              offsetLg="2"
              sizeMd="10"
              offsetMd="1"
              sizeSm="12"
            >
              <S.Suggestions>
                <IonRow>
                  <IonCol size="12" sizeLg="auto">
                    <S.FlexContainer>
                      <S.TopicsHeader>Ways I can help you:</S.TopicsHeader>

                      <S.TopicsContainer>
                        {topics.map((topic) => (
                          <IonButton
                            key={topic.name}
                            size="small"
                            color="neutral"
                            fill={activeTopicName === topic.name ? 'solid' : 'outline'}
                            onClick={() => onTopicClick(topic.name)}
                          >
                            {topic.name}
                          </IonButton>
                        ))}
                      </S.TopicsContainer>
                    </S.FlexContainer>
                  </IonCol>

                  <IonCol size="auto">
                    <S.DividerImg
                      className="ion-hide-lg-down"
                      src="/assets/images/divider.svg"
                      alt="|"
                    />
                  </IonCol>

                  <IonCol size="12" sizeLg="7">
                    <S.SuggestionsContainer>
                      {questions.map((question) => (
                        <IonButton
                          key={question}
                          color="ocean"
                          onClick={() => onQuestionClick(question)}
                        >
                          {question}
                        </IonButton>
                      ))}
                    </S.SuggestionsContainer>
                  </IonCol>
                </IonRow>
              </S.Suggestions>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

export default Home;
