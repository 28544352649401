import React from 'react';
import { IonPage, IonContent, IonCol, IonGrid, IonRow, IonImg } from '@ionic/react';
import Header from '../components/Header';

import './AboutUs.scss';

const AboutUs: React.FC = () => {
  return (
    <IonPage>
      <IonContent className="aboutus-content">
        <Header />
        <main className="ion-padding">
          <IonGrid>
            <IonRow>
              <IonCol>
                <h3 className="section-title">About Us</h3>
                <p className="capsule">Find & Understand Property</p>
                <p className="capsule">Actionable Information</p>
              </IonCol>
              <IonCol size="auto">
                <IonImg className="divider" src="/assets/images/divider.svg" alt="|" />
              </IonCol>
              <IonCol size="8">
                <p className="section-description">
                  Welcome to Terr.ai, where we believe that every homeowner deserves clear, accessible, and actionable real estate information. Our journey began with a simple idea: to harness the power of AI to eliminate the frustration that comes with finding and understanding property-related information.
                </p>
              </IonCol>
            </IonRow>
          </IonGrid>

          <IonGrid>
            <IonRow>
              <IonCol>
                <h3 className="section-title">Our Mission</h3>
              </IonCol>
              <IonCol size="auto">
                <IonImg className="divider" src="/assets/images/divider.svg" alt="|" />
              </IonCol>
              <IonCol size="8">
                <p className="section-description">
                  At Terr.ai, our mission is to democratize real estate knowledge through cutting-edge AI technology. We’re here to bridge the gap between complex legal documents and everyday homeowners, turning data into insights that are easy to understand and act upon. By simplifying the search for information and translating legalese into plain language, we empower you to make informed decisions about your property.
                </p>
              </IonCol>
            </IonRow>
          </IonGrid>

          <IonGrid>
            <IonRow>
              <IonCol>
                <h3 className="section-title">Why We Exist</h3>
              </IonCol>
              <IonCol size="auto">
                <IonImg className="divider" src="/assets/images/divider.svg" alt="|" />
              </IonCol>
              <IonCol size="8">
                <p className="section-description">
                  Navigating the complexities of real estate and homeownership can be overwhelming, with lengthy HOA documents, conflicting local regulations, confusing legal terms, and the stress of home renovations, repairs, and maintenance. That's where we come in. Our AI-powered platform is your personalized guide, simplifying every aspect of homeownership. We provide tailored, relevant information specific to your home and needs—not just generic advice. With us, you'll find it easier to understand, manage, and enhance your homeownership experience.
                </p>
              </IonCol>
            </IonRow>
          </IonGrid>
          
          <IonGrid>
            <IonRow>
              <IonCol>
                <h3 className="section-title">Join Us</h3>
              </IonCol>
              <IonCol size="auto">
                <IonImg className="divider" src="/assets/images/divider.svg" alt="|" />
              </IonCol>
              <IonCol size="8">
                <p className="section-description">
                  Whether you’re a first-time homeowner, a seasoned property owner, or part of an HOA community, Terr.ai is here to support you. Together, with the power of AI, we can make real estate less about confusion and more about confidence.
                </p>
              </IonCol>
            </IonRow>
          </IonGrid>

          <IonGrid>
            <IonRow>
              <IonCol>
                <h3 className="section-title">Got Questions? We’ve Got Answers!</h3>
              </IonCol>
              <IonCol size="auto">
                <IonImg className="divider" src="/assets/images/divider.svg" alt="|" />
              </IonCol>
              <IonCol size="8">
                <p className="section-description">
                  <span>We're here to help with all your township inquiries. Whether you need information, have a suggestion, or just want to chat, don’t hesitate to reach out at <a href="mailto:hello@terr.ai">hello@terr.ai</a>!</span>
                </p>
              </IonCol>
            </IonRow>
          </IonGrid>

        </main>
      </IonContent>
    </IonPage>
  );
};

export default AboutUs;
