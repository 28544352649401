export const environment = {
  sentryDsn: 'https://f00fe29f2c0d752d703b60ef88c3dcdb@o4507788926976000.ingest.us.sentry.io/4507788938575872',
  // api: 'https://terrai-431617.web.app/api',
  // api: 'http://localhost:3000/api',
  api: '/api',
  production: false,
  stage: 'production',
  streamChatApiKey: 'fbuaq2fdrt6z',
  supabaseUrl: 'https://cnzcuyjdgrausinupphy.supabase.co',
  supabaseAnonKey: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6ImNuemN1eWpkZ3JhdXNpbnVwcGh5Iiwicm9sZSI6ImFub24iLCJpYXQiOjE3MjIzNzQ0MjMsImV4cCI6MjAzNzk1MDQyM30._o__biLbygGlCOzvMXZTIDwOmKTRvw-ZQ4rTvrZyHlo',
  posthogUrl: 'https://us.i.posthog.com',
  posthogKey: 'phc_8nns1iPWiNpIzNqW6qNa0MLUTZVTe0hIYvk5okVVhP3',
  mapboxApiKey: 'pk.eyJ1IjoibmlrZWVwIiwiYSI6ImNsenJhd241MzF5aWUyaXB4eXc5dmRmYWoifQ.cSKHS-JGEVky6zK-dsMMOQ'
};
