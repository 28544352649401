import { environment } from '@env';
import mapboxgl from 'mapbox-gl';
import React, { useEffect, useRef } from 'react';

import S from './MapboxMap.styles';

const MapboxMap = ({ houseCoordinates }: { houseCoordinates: [number, number] }) => {
  const mapContainer = useRef<HTMLDivElement>(null);

  useEffect(() => {
    mapboxgl.accessToken = environment.mapboxApiKey;

    // Hide the container, so we don't get a screen flicker
    if (mapContainer.current) {
      mapContainer.current.style.opacity = '0';
    }

    // Initialize the map
    const map = new mapboxgl.Map({
      container: mapContainer.current!,
      style: 'mapbox://styles/mapbox/satellite-v9', // Changed to satellite view
      center: houseCoordinates,
      zoom: 19,
      pitch: 60,
      bearing: 80,
      interactive: false,
      trackResize: true,
    });

    map.on('load', () => {
      // Remove Mapbox logo
      const logoEl = mapContainer.current?.querySelector('.mapboxgl-ctrl-logo');
      if (logoEl) {
        logoEl.remove();
      }

      // Remove Mapbox attribution
      const attrEl = mapContainer.current?.querySelector('.mapboxgl-ctrl-attrib');
      if (attrEl) {
        attrEl.remove();
      }

      // Resize map so it fits the container
      map.resize();
      // And show the container after resizing
      if (mapContainer.current) {
        mapContainer.current.style.opacity = '1';
      }

      // Add a marker to highlight the coordinates
      new mapboxgl.Marker({
        color: "#FF0000",
        scale: 0.8
      })
        .setLngLat(houseCoordinates)
        .addTo(map);

      // Add a pulsing dot effect
      const size = 80;
      const pulsingDot = {
        width: size,
        height: size,
        data: new Uint8Array(size * size * 4),
        context: null as CanvasRenderingContext2D | null, // Add this line
        onAdd: function () {
          const canvas = document.createElement('canvas');
          canvas.width = this.width;
          canvas.height = this.height;
          this.context = canvas.getContext('2d');
        },
        render: function () {
          const duration = 1000;
          const t = (performance.now() % duration) / duration;
          const radius = (size / 2) * 0.3;
          const outerRadius = (size / 2) * 0.7 * t + radius;
          const context = this.context;
          if (context) { // Add this check
            context.clearRect(0, 0, this.width, this.height);
            context.beginPath();
            context.arc(this.width / 2, this.height / 2, outerRadius, 0, Math.PI * 2);
            context.fillStyle = `rgba(255, 200, 200, ${1 - t})`;
            context.fill();
            context.beginPath();
            context.arc(this.width / 2, this.height / 2, radius, 0, Math.PI * 2);
            context.fillStyle = 'rgba(255, 100, 100, 1)';
            context.strokeStyle = 'white';
            context.lineWidth = 2 + 4 * (1 - t);
            context.fill();
            context.stroke();
            this.data = new Uint8Array(context.getImageData(0, 0, this.width, this.height).data.buffer);
            map.triggerRepaint();
            return true;
          }
        }
      };

      map.addImage('pulsing-dot', pulsingDot, { pixelRatio: 2 });

      map.addSource('dot-point', {
        'type': 'geojson',
        'data': {
          'type': 'FeatureCollection',
          'features': [
            {
              'type': 'Feature',
              'properties': {},
              'geometry': {
                'type': 'Point',
                'coordinates': houseCoordinates
              }
            }
          ]
        }
      });

      map.addLayer({
        'id': 'layer-with-pulsing-dot',
        'type': 'symbol',
        'source': 'dot-point',
        'layout': {
          'icon-image': 'pulsing-dot'
        }
      });
    });

    return () => map.remove();
  }, [houseCoordinates]);

  return (
    <S.MapContainer ref={mapContainer}/>
  );
};

export default MapboxMap;
