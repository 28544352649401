import {
  IonButton,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCheckbox,
  IonCol,
  IonContent,
  IonGrid,
  IonIcon,
  IonLabel,
  IonPage,
  IonRow,
  IonText,
} from '@ionic/react';
import { logoGoogle } from 'ionicons/icons';
import React, { useState } from 'react';
import { Link, Redirect, useHistory, useLocation } from 'react-router-dom';
import Header from '../components/Header';

import LLMChatBubble from '../components/LLMChatBubble';
import { Routes } from '../config/routes';
import { useAuth } from '../context/AuthProvider';
import './Login.scss';

const LoginSignup: React.FC = () => {
  const { user, signInWithPassword, signUp } = useAuth();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState<string | null>(null);
  const [confirmEmail, setConfirmEmail] = useState(false);
  const [agreedToTerms, setAgreedToTerms] = useState(false); // State for terms and conditions checkbox
  const history = useHistory();
  const { signInWithOAuth } = useAuth();
  const location = useLocation();

  const isSignup = location.pathname === Routes.signup;

  const oAuth = async () => {
    await signInWithOAuth({
      provider: 'google',
      options: {
        redirectTo: window.location.href.replace(history.location.pathname, Routes.chat),
      },
    });
  };

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    setError(null);

    try {
      if (isSignup) {
        const { data, error: signUpError } = await signUp({ email, password });
        if (signUpError) throw signUpError;
        if (data.session) {
          history.push(Routes.chat);
        } else {
          setConfirmEmail(true);
        }
      } else {
        const { data, error: signInError } = await signInWithPassword({ email, password });
        if (signInError) throw signInError;
        if (data.session) {
          history.push(Routes.chat);
        }
      }
    } catch (error: any) {
      setError(error?.message || error);
    }
  };

  if (user && !user.is_anonymous) return <Redirect to={Routes.chat} />;

  return (
    <IonPage>
      <IonContent className="login-content">
        <Header logoOnly={true} />
        <div className="login-container">
          <IonGrid className="ion-no-padding">
            <IonRow>
              <IonCol size="12" sizeMd="6">
                <IonCard className="login-ion-card">
                  <IonCardHeader className="pl-0">
                    <h2>
                      {confirmEmail
                        ? 'Verify your email address'
                        : 'Your Home and Community, Instantly Answered'}
                    </h2>
                  </IonCardHeader>
                  {confirmEmail ? (
                    <IonCardContent className="pl-0 verify-email">
                      <p>
                        To use Terr.ai, click the verification button in the email we sent to{' '}
                        {email}. This helps keep your account secure.
                      </p>
                      <p className="mt-20">
                        Wrong address? <Link to={Routes.login}>Logout</Link> to sign in with a
                        different email. If you mistyped your email when signing up, create a new
                        account.
                      </p>
                    </IonCardContent>
                  ) : (
                    <IonCardContent>
                      <form onSubmit={handleSubmit}>
                        <div className="login-form mb-8">
                          <input
                            placeholder="Email"
                            type="text"
                            value={email}
                            className="ionic-input ion-margin-bottom"
                            onChange={(e) => setEmail(e.target.value!)}
                          />
                          <input
                            placeholder="Password"
                            type="password"
                            value={password}
                            className="ionic-input"
                            onChange={(e) => setPassword(e.target.value!)}
                          />
                        </div>

                        {isSignup && (
                          <div className="terms-checkbox mt-20 mb-10">
                            <IonCheckbox
                              checked={agreedToTerms}
                              onIonChange={(e) => setAgreedToTerms(e.detail.checked)}
                            />
                            <IonLabel className="ml-10">
                              I agree to the{' '}
                              <Link to={Routes.termsOfService} target="_blank">
                                Terms and Conditions
                              </Link>
                            </IonLabel>
                          </div>
                        )}

                        <IonButton
                          expand="block"
                          shape="round"
                          type="submit"
                          className="dark-button ion-margin-bottom mt-25"
                          disabled={isSignup && !agreedToTerms} // Disable button if terms are not agreed to
                        >
                          {isSignup ? 'Sign Up' : 'Log In'}
                        </IonButton>

                        <IonButton
                          expand="block"
                          shape="round"
                          onClick={oAuth}
                          className="dark-button ion-no-padding ion-margin-bottom"
                        >
                          <IonIcon icon={logoGoogle} slot="start" />
                          {isSignup ? 'Sign Up' : 'Sign In'} with Google
                        </IonButton>
                      </form>

                      {error && <IonText color="danger">{error}</IonText>}

                      <div className="signup-section">
                        <IonText color="medium" className="or-text">
                          OR
                        </IonText>
                        <IonButton
                          expand="block"
                          shape="round"
                          routerLink={isSignup ? Routes.login : Routes.signup}
                          className="dark-button mt-38"
                        >
                          {isSignup ? 'Log In' : 'Sign Up'}
                        </IonButton>
                        <div className="footer">
                          <Link
                            to={Routes.termsOfService}
                            className="mr-20 color-gray"
                            target="_blank"
                          >
                            Terms & Conditions
                          </Link>
                          <Link to={Routes.privacyPolicy} className="color-gray" target="_blank">
                            Privacy Policy
                          </Link>
                        </div>
                      </div>
                    </IonCardContent>
                  )}
                </IonCard>
              </IonCol>

              <IonCol>
                <IonCard className="login-right-side">
                  <div>
                    <LLMChatBubble>Ways I can help</LLMChatBubble>
                  </div>
                  <div className="recommendation-bubble mb-8 ml-32">
                    Where can I make my sewer bill payment?
                  </div>
                  <div className="recommendation-bubble mb-8 ml-32">
                    What are the bus routes in Princeton?
                  </div>
                  <div className="recommendation-bubble mb-8 ml-32">
                    What is the contact number for animal control?
                  </div>
                  <div className="recommendation-bubble mb-8 ml-32">
                    What are the average property taxes in Princeton?
                  </div>
                </IonCard>
                {/*<IonCard className="login-right-side">
                  <IonCardHeader className="pl-0">
                    <h2>Get instant answers to all your local municipality questions</h2>
                  </IonCardHeader>
                  <IonButton
                    expand="block"
                    disabled
                    fill="outline"
                    className="button-item ion-margin-bottom"
                  >
                    <span className="color-gray">MUNICIPAL INFORMATION</span>
                    <IonIcon color="success" icon={checkmarkCircleOutline} slot="end" />
                  </IonButton>
                  <IonButton
                    expand="block"
                    fill="outline"
                    className="button-item ion-margin-bottom blur-effect"
                    title="Coming Soon"
                  >
                    <span className="color-gray">CENTRALIZED DOCUMENTS</span>
                    <IonIcon icon={lockClosedOutline} slot="end" />
                  </IonButton>
                  <IonButton
                    expand="block"
                    fill="outline"
                    className="button-item ion-margin-bottom blur-effect"
                    title="Coming Soon"
                  >
                    <span className="color-gray">HISTORY OF IMPROVEMENTS</span>
                    <IonIcon icon={lockClosedOutline} slot="end" />
                  </IonButton>
                  <IonButton
                    expand="block"
                    fill="outline"
                    className="button-item ion-margin-bottom blur-effect"
                    title="Coming Soon"
                  >
                    <span className="color-gray">MAINTENANCE CHECKLIST</span>
                    <IonIcon icon={lockClosedOutline} slot="end" />
                  </IonButton>
                </IonCard>*/}
              </IonCol>
            </IonRow>
          </IonGrid>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default LoginSignup;
