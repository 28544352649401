import { IonButtons, IonHeader, IonMenuButton, IonToolbar } from '@ionic/react';
import React from 'react';

import './Header.scss';
import Menu from './Menu';
import TerraiLogo from './TerraiLogo';

interface HeaderProps {
  logoOnly?: boolean;
}

const Header: React.FC<HeaderProps> = ({ logoOnly = false }) => {
  return (
    <IonHeader className={`ion-no-border`}>
      <IonToolbar>
        <IonButtons slot="start" className="ion-hide-md-up">
          <IonMenuButton color="darkgray" />
        </IonButtons>

        <div className="ion-float-md-start ion-padding-start ion-margin-start">
          <TerraiLogo />
        </div>
        {!logoOnly ? (
          <IonButtons slot="end" className="ion-hide-md-down">
            <Menu orientation="horizontal" />
          </IonButtons>
        ) : null}
        {/*<IonTitle className={`${styles.title} ion-text-center`}>Terr.ai</IonTitle>*/}
      </IonToolbar>
    </IonHeader>
  );
};

export default Header;
