import { IonSpinner } from '@ionic/react';
import {
  DefaultStreamChatGenerics,
  TypingIndicatorProps,
  useChannelStateContext,
  useChatContext,
  useTypingContext,
} from 'stream-chat-react';
import LLMChatBubble from '../LLMChatBubble';

const TypingIndicator: React.FC<TypingIndicatorProps> = ({ threadList }) => {
  const { channelConfig, thread } =
    useChannelStateContext<DefaultStreamChatGenerics>('TypingIndicator');
  const { client } = useChatContext<DefaultStreamChatGenerics>('TypingIndicator');
  const { typing = {} } = useTypingContext<DefaultStreamChatGenerics>('TypingIndicator');

  const typingInChannel = !threadList
    ? Object.values(typing).filter(
        ({ parent_id, user }) => user?.id !== client.user?.id && !parent_id
      )
    : [];

  const typingInThread = threadList
    ? Object.values(typing).filter(
        ({ parent_id, user }) => user?.id !== client.user?.id && parent_id === thread?.id
      )
    : [];

  const isTypingActive =
    (threadList && typingInThread.length > 0) || (!threadList && typingInChannel.length > 0);

  if (channelConfig?.typing_events === false) {
    return null;
  }

  if (!isTypingActive) return null;

  return (
    <LLMChatBubble>
      <IonSpinner name="dots" />
    </LLMChatBubble>
  );
};

export default TypingIndicator;
