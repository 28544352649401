import { IonButton } from '@ionic/react';
import classnames from 'classnames';
import React, { useCallback, useMemo } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { Routes } from '../config/routes';
import { useAuth } from '../context/AuthProvider';
import './Menu.scss';

interface MenuItem {
  text: string;
  link?: string;
  shape?: string;
  fill?: string;
  className?: string;
  onClick?: () => void;
}

interface MenuProps {
  orientation: 'horizontal' | 'vertical';
}

const Menu: React.FC<MenuProps> = ({ orientation }) => {
  const history = useHistory();
  const location = useLocation();
  const { user, signOut } = useAuth();

  const handleLogout = useCallback(async () => {
    await signOut();

    history.push(Routes.login); // Redirect to login page after logout
  }, [history, signOut]);

  const menuItems: MenuItem[] = useMemo(() => {
    if (!user || user.is_anonymous)
      return [
        { text: 'About Us', link: Routes.aboutUs },
        // { text: 'Join the Waitlist', link: Routes.waitlist },
        { text: 'Login', link: Routes.login },
        { text: 'Start a Chat', link: Routes.chat, className: 'start-chat-button' },
      ];

    return [
      { text: 'Dashboard', link: Routes.dashboard},
      { text: 'Chat', link: Routes.chat },
      { text: 'LOGOUT', onClick: handleLogout },
    ];
  }, [user, handleLogout]);

  return (
    <>
      {menuItems.map((item, index) => (
        <IonButton
          key={item.text}
          className={classnames('tai-menu ion-text-start', item.className, {
            'active': item.link === location.pathname && location.pathname !== Routes.chat
          })}
          routerLink={item.onClick ? undefined : item.link} // If onClick exists, don't use routerLink
          shape={item.shape as any}
          fill={(item.fill as any) || 'clear'}
          onClick={item.onClick}
        >
          <span className="menu-item-text">{item.text}</span>
        </IonButton>
      ))}
    </>
  );
};

export default Menu;
