import { IonIcon } from '@ionic/react';
import { sendOutline } from 'ionicons/icons';

import S from './TerraiMessageInput.styles';

interface Props extends Partial<React.InputHTMLAttributes<HTMLInputElement>> {
  value: string;
  autoFocus?: boolean;
  onValueChange: (message: string) => void;
}

const TerraiMessageInput: React.FC<Props> = ({
  autoFocus = true,
  placeholder = 'Ask a question to get started…',
  value,
  onValueChange,
  ...inputProps
}) => {
  return (
    <S.Component>
      <S.Input
        {...inputProps}
        placeholder={placeholder}
        ref={(input) => !!autoFocus && input?.focus()}
        value={value}
        onChange={(e) => onValueChange(e.target.value)}
      />

      <S.Button color="transparent" type="submit" size="small">
        <IonIcon slot="icon-only" icon={sendOutline} color="darkgray" size="small" />
      </S.Button>
    </S.Component>
  );
};

export default TerraiMessageInput;
