import { IonButton } from '@ionic/react';
import styled from 'styled-components';
import { breakpointSmDown } from '../../theme/breakpoints';

const Wrapper = styled.div`
  margin-bottom: 1rem;
`;

const StyledIonButton = styled(IonButton)`
  display: block;
  width: fit-content;

  & + & {
    margin-top: 0.5rem;
  }

  @media screen and (max-width: ${breakpointSmDown}) {
    width: 100%;
  }
`;

export default {
  Wrapper,
  IonButton: StyledIonButton,
};
