import { IonImg } from '@ionic/react';
import React from 'react';

import S from './LLMChatBubble.styles';


interface LLMChatBubbleProps {
  children: React.ReactNode;
}

const LLMChatBubble: React.FC<LLMChatBubbleProps> = ({ children }) => (
  <S.IonList>
    <S.IonItem lines="none">
      <S.IonAvatar slot="start">
        <IonImg src="./assets/images/terri-logo.svg" alt="Terri" />
      </S.IonAvatar>

      <div>
        {children}
      </div>
    </S.IonItem>
  </S.IonList>
);

export default LLMChatBubble;
