import styled from 'styled-components';

const Title = styled.h1`
  margin-top: 12rem;
`;

const MainContainer = styled.main`
  max-width: 1200px;
  margin: 0 auto;
  padding: 1rem;
`;

const ChatContainer = styled.div`
  margin-top: 1rem;
  margin-bottom: 2rem;
`;

const ChatFooter = styled.footer`
  background-color: var(--figma-color-neutral-100);
  border-top: 1px solid var(--figma-color-neutral-200);
  padding: 0.625rem 1.25rem;
  text-align: center;
  position: fixed; /* Fix the footer at the bottom */
  bottom: 0;
  width: 100%;
  left: 0;
  z-index: 9999;

  p {
    margin: 0;
    line-height: 1;
  }
`;

export default {
  Title,
  MainContainer,
  ChatContainer,
  ChatFooter,
};
