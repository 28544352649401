import { IonContent, IonPage, IonProgressBar } from '@ionic/react';
import React, { useEffect, useMemo } from 'react';
import { Redirect, useLocation } from 'react-router-dom';

import ContentGradient from '../components/ContentGradient';
import Header from '../components/Header';
import StreamChatInitializer from '../components/stream-chat/StreamChatInitializer';
import { Routes } from '../config/routes';
import { useAuth } from '../context/AuthProvider';
import S from './Chat.styles';

export interface ChatRouteState {
  question?: string;
  isUnauthenticatedExperience?: boolean;
}

enum AuthStatus {
  authenticated,
  loginRequired,
  processing,
}

const Chat: React.FC = () => {
  const location = useLocation<ChatRouteState>();

  const { isAuthenticated, shouldHaveAuthToken, signInAnonymously } = useAuth();

  useEffect(() => {
    if (isAuthenticated) return;

    if (!location.state?.isUnauthenticatedExperience) return;

    // Make sure that we aren't trying to create an anonymous user,
    // before authenticating as an existing user.
    if (!shouldHaveAuthToken) signInAnonymously();
  }, [location.state, isAuthenticated, shouldHaveAuthToken, signInAnonymously]);

  const authStatus: AuthStatus = useMemo(() => {
    if (isAuthenticated) return AuthStatus.authenticated;

    if (shouldHaveAuthToken) return AuthStatus.processing;

    if (!location.state?.isUnauthenticatedExperience) return AuthStatus.loginRequired;

    return AuthStatus.processing;
  }, [isAuthenticated, shouldHaveAuthToken, location.state]);

  if (authStatus === AuthStatus.loginRequired) {
    return <Redirect to={Routes.login} />;
  }

  if (authStatus === AuthStatus.processing) {
    return (
      <IonPage>
        <IonContent className="chat-content">
          <ContentGradient />
          <Header />
          <IonProgressBar type="indeterminate" />;
        </IonContent>
      </IonPage>
    );
  }

  return (
    <IonPage>
      <IonContent className="chat-content">
        <ContentGradient />
        <div className='mt-10'>
          <Header />
        </div>

        <S.MainContainer>
          <S.Title>
            Hi there, I'm Terri!
            <br />
            Your go-to guide for everything in your community.
            <br />
            Ask me a question. I'm here to help!
          </S.Title>

          <S.ChatContainer>
            <StreamChatInitializer />
          </S.ChatContainer>
        </S.MainContainer>

        <S.ChatFooter>
          <p>
            <small>Do not include personal or sensitive information in your message.</small>
          </p>
        </S.ChatFooter>
      </IonContent>
    </IonPage>
  );
};

export default Chat;
