import { IonAvatar, IonItem, IonList } from '@ionic/react';
import styled from 'styled-components';

const StyledIonList = styled(IonList)`
  background: transparent;
`;

const StyledIonItem = styled(IonItem)`
  --background: transparent;
  margin-bottom: 0.5rem;
  color: var(--figma-color-darkgray-300);
`;

const StyledIonAvatar = styled(IonAvatar)`
  --border-radius: 6px;
  align-self: flex-start;
`;

export default {
  IonList: StyledIonList,
  IonItem: StyledIonItem,
  IonAvatar: StyledIonAvatar,
};
