export enum Routes {
  home = '/',
  dashboard = '/dashboard',
  login = '/login',
  signup = '/signup',
  chat = '/chat',
  properties = '/properties',
  createProperty = '/properties/add',
  viewProperty = '/properties/:id',
  aboutUs = '/about-us',
  privacyPolicy = '/privacy-policy',
  termsOfService = '/terms-of-service',
}
