interface Props {
  children: string;
}

const ChatBubble: React.FC<Props> = ({ children }) => (
  <div className="str-chat__message-list-scroll">
    <div className="str-chat__li str-chat__li--single">
      <div className="str-chat__message str-chat__message-simple str-chat__message--regular str-chat__message--received str-chat__message--me str-chat__message-simple--me str-chat__message--has-text">
        <div
          className="str-chat__avatar str-chat__avatar--circle str-chat__message-sender-avatar"
          data-testid="avatar"
          title="Test User"
          style={{
            flexBasis: '32px',
            fontSize: '16px',
            height: '32px',
            lineHeight: '32px',
            width: '32px',
          }}
        >
          <div className="str-chat__avatar-fallback" data-testid="avatar-fallback">
            T
          </div>
        </div>
        <div className="str-chat__message-inner" data-testid="message-inner">
          <div
            className="str-chat__message-simple__actions str-chat__message-options"
            data-testid="message-options"
          ></div>
          <div className="str-chat__message-reactions-host"></div>
          <div className="str-chat__message-bubble">
            <div className="str-chat__message-text">
              <div
                className="str-chat__message-text-inner str-chat__message-simple-text-inner"
                data-testid="message-text-inner-wrapper"
              >
                <div>
                  <p>{children}</p>
                </div>
              </div>
            </div>
            <div className="str-chat__message-error-icon">
              <svg
                data-testid="error"
                fill="none"
                height="24"
                viewBox="0 0 24 24"
                width="24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2Z"
                  fill="black"
                  id="background"
                ></path>
                <path d="M13 17H11V15H13V17ZM13 13H11V7H13V13Z" fill="white"></path>
              </svg>
            </div>
          </div>
        </div>
        <div className="str-chat__message-data str-chat__message-simple-data str-chat__message-metadata">
          <span
            className="str-chat__message-simple-status str-chat__message-status"
            data-testid="message-status-read-by"
          >
            <div
              className="str-chat__avatar str-chat__avatar--circle str-chat__message-sender-avatar"
              data-testid="avatar"
              title="Terri"
              style={{
                flexBasis: '15px',
                fontSize: '7.5px',
                height: '15px',
                lineHeight: '15px',
                width: '15px',
              }}
            >
              <img
                alt="T"
                className="str-chat__avatar-image str-chat__avatar-image--loaded"
                data-testid="avatar-img"
                src="https://terrai-431617.web.app/assets/images/terri-logo.svg"
                style={{ flexBasis: '15px', height: '15px', objectFit: 'cover', width: '15px' }}
              />
            </div>
          </span>
        </div>
      </div>
    </div>
  </div>
);

export default ChatBubble;
