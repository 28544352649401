

import React, { useContext, useState } from 'react';
import { MessageText, useMessageContext } from 'stream-chat-react';

import { ChatContext } from '../utils/ChatContext';
import './TerraiMessageUI.scss';
import LLMChatBubble from './LLMChatBubble';

type RatingType = 'positive' | 'negative';

const LLMResponseRating = ({ onRate, currentRating }: any) => {
  const [rating, setRating] = useState<RatingType | null>(null);

  const handleRating = (rate: RatingType) => {
    setRating(rate);
    onRate(rate);
  };

  return (
    <div style={{ display: 'flex', gap: '5px', marginTop: '5px' }}>
      <button onClick={() => handleRating('positive')} disabled={rating === 'positive'} style={{ background: 'transparent' }}>
        {currentRating === 'positive' ? <img src="./assets/images/positive-rating-selected.svg" alt="Positive Rating" /> : <img src="./assets/images/positive-rating.svg" alt="Positive Rating" />}
      </button>
      <button onClick={() => handleRating('negative')} disabled={rating === 'negative'} style={{ background: 'transparent' }}>
        {currentRating === 'negative' ? <img src="./assets/images/negative-rating-selected.svg" alt="Negative Rating" /> : <img src="./assets/images/negative-rating.svg" alt="Negative Rating" />}
      </button>
    </div>
  );
};

const TerraiMessageUI = () => {
  const { channel } = useContext(ChatContext);
  const { message } = useMessageContext(); // Access the current message

  const handleRate = async (rating: RatingType) => {
    try {
      await channel?.sendReaction(message.id, {
        type: 'rate',
        rating,
        user_id: message.user?.id,
        message_id: message.id,
      });
    } catch (error) {
      console.error('Error sending reaction:', error);
    }
  };

  const renderUserChatBubble = (messageText: string) => (
    <div className="str-chat__li str-chat__li--single">
      <div className="str-chat__message str-chat__message-simple str-chat__message--regular str-chat__message--received str-chat__message--me str-chat__message-simple--me str-chat__message--has-text">
        <div className="str-chat__message-inner" data-testid="message-inner">
          <div className="str-chat__message-bubble">
            <div className="str-chat__message-text">
              <div>
                <p>{messageText}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  const renderLLMChatBubble = (messageText: string, rating: any) => (
    <LLMChatBubble>
      <MessageText />
      <LLMResponseRating onRate={handleRate} currentRating={rating} />
    </LLMChatBubble>
  );

  // If no message is available, log and return an empty element
  if (!message) {
    console.log('No message, skipping... ', message);
    return <span />;
  }

  return (
    <>
      {message.user?.role === 'admin' ? renderLLMChatBubble(message.text!, message?.own_reactions?.length ? message.own_reactions[0].rating : '') : renderUserChatBubble(message.text!)}
    </>
  );
};

export default TerraiMessageUI;
