import { IonImg } from '@ionic/react';
import React from 'react';
import { Link } from 'react-router-dom';

import { Routes } from '../config/routes';
import { useAuth } from '../context/AuthProvider';

interface TerraiLogoProps {
  width?: string;
  height?: string;
}

const TerraiLogo: React.FC<TerraiLogoProps> = ({ width = '150px', height = 'auto' }) => {
  const { isAuthenticated } = useAuth();

  return (
    <Link to={isAuthenticated ? Routes.dashboard : Routes.home} className="logo-link">
      <div className="logo-text ion-padding-end">
        <IonImg src="/assets/images/terrai-logo.png" alt="Terr.ai" style={{ width, height }} />
      </div>
    </Link>
  );
};

export default TerraiLogo;
