import styled from 'styled-components'

const Container = styled.div`
  --gradient-from-color: var(--figma-color-neutral-200);
  --gradient-from-position: -80%;
  --gradient-to-color: #57b9e4;
  --gradient-to-position: 180%;
  --gradient-angle: -70deg;
  --background: #dfddbe;

  height: 36vw;
  position: fixed;
  top: -15vw;
  left: 0;
  right: 0;
  filter: blur(10vw);
  z-index: -1;
`

const Gradient = styled.div`
  background: linear-gradient(
    var(--gradient-angle),
    var(--gradient-from-color) var(--gradient-from-position),
    var(--gradient-to-color) var(--gradient-to-position)
  );
  position: absolute;
  top: 0;
  bottom: 0;
  left: 6%;
  right: 6%;
  border-radius: 50%;
`

const Solid = styled.div`
  background: var(--background);
  position: absolute;
  top: 0;
  bottom: 35%;
  left: 6%;
  right: 6%;
  border-radius: 50%;
`

export default {
  Container,
  Gradient,
  Solid,
}
