import React from 'react';
import { IonPage, IonHeader, IonToolbar, IonTitle, IonContent } from '@ionic/react';
import Header from '../components/Header';
import './AboutUs.scss';

const PrivacyPolicy: React.FC = () => {
  return (
    <IonPage>
      <IonContent className="aboutus-content">
        <Header />
        <main className="ion-padding">
          <h3 className="section-title">TERR.AI PRIVACY POLICY</h3>
          <p className="section-description pl-0">
            Last Updated: Aug 17 2024
            This Privacy Policy describes how Terr.ai (collectively, “Terr.ai,” “we,” or “us”) collects, uses, and
            discloses information about you. This Privacy Policy applies to information we collect when you access or
            use our website and other services (collectively, our “Services”), or when you otherwise interact with us
            online, such as through our customer support channels, or on social media pages. We may provide different
            or additional notices of our privacy practices with respect to certain services, in which case this Privacy
            Policy will not apply.
          </p>
          <p className="section-description pl-0">
            We may change this Privacy Policy from time to time. If we make changes, we will notify you by revising
            the date at the top of this policy. We may also provide you with additional notice (for example, by adding a
            statement to the Services or sending you a notification), such as in connection with making material changes
            to this Privacy Policy. We encourage you to review this Privacy Policy regularly to stay informed about our
            information practices and the choices available to you.
          </p>

          <h3 className="section-title">COLLECTION OF INFORMATION</h3>
          <p className="section-description pl-0">
            <b>Information You Provide to Us</b>
          </p>
          <p className="section-description pl-0">
            We collect information you provide directly to us. For example, we collect information directly from you
            when you create an account, fill out a form, submit or post content through our Services, communicate
            with us via third-party platforms, or otherwise communicate with us. The specific information we collect
            depends on the context in which you provide it, and could include:
          </p>
          <p className="section-description pl-0">
            <ul>
              <li>email address;</li>
              <li>postal address;</li>
              <li>any other information you choose to provide.</li>
            </ul>
          </p>
          <p className="section-description pl-0">
            <b>Information We Collect Automatically</b>
          </p>
          <p className="section-description pl-0">We automatically collect certain information about your interactions with us or our Services, including:</p>
          <p className="section-description pl-0">
            <ul>
              <li>Activity Information: We collect information about your activity on our Services, such as when you
                use the chat feature.
              </li>
              <li>Device and Usage Information: We collect information about how you access our Services,
                including data about the device and network you use, such as your hardware model, operating system
                version, IP address, unique device identifiers, browser type, and app version. We also collect
                information about your activity on our Services, such as access times, pages viewed, links clicked,
                and the page you visited before navigating to our Services.
              </li>
              <li>Information Collected by Cookies and Similar Tracking Technologies: We (and those who
                perform work for us) use tracking technologies, such as cookies and web beacons, to collect
                information about your interactions with the Services. Cookies are small data files stored on your
                hard drive or in device memory that help us improve our Services and your experience, see which
                areas and features of our Services are popular, and count visits. Web beacons (also known as “pixel
                tags” or “clear GIFs”) are electronic images that we use on our Services and in our emails to help
                deliver cookies, count visits, and understand usage and campaign effectiveness. For more information
                about cookies and how to disable them, see the Your Choices section below.
              </li>
            </ul>
          </p>
          <p className="section-description pl-0">
            <b>Information We Derive</b>
          </p>
          <p className="section-description pl-0">
            We may derive information or draw inferences about you based on the information we collect. For example,
            we may make inferences about your approximate location based on your IP address.
          </p>
          <h3 className="section-title">USE OF INFORMATION</h3>
          <p className="section-description pl-0">
            We use the information we collect to provide you services and customize your experience. We also use
            the information we collect to:
          </p>
          <p className="section-description pl-0">
            <ul>
              <li>Provide, maintain, improve, and develop new products and services, including to debug and repair
                errors in our Services;
              </li>
              <li>Personalize your experience with us;
              </li>
              <li>Send you technical notices, security alerts, support messages and other transactional or relationship
                messages;
              </li>
              <li>Communicate with you about services, and events offered by Terr.ai and others and provide news
                and information that we think will interest you;
              </li>
              <li>Monitor and analyze trends, usage, and activities in connection with our products and services;
              </li>
              <li>Detect, investigate, and help prevent security incidents and other malicious, deceptive, fraudulent,
                or illegal activity and help protect the rights and property of Terr.ai and others;
              </li>
              <li>Comply with our legal and financial obligations;
              </li>
              <li>Create de-identified, anonymized or aggregated information; and
              </li>
              <li>Carry out any other purpose described to you at the time the information was collected.
              </li>
            </ul>
          </p>
          <h3 className="section-title">DISCLOSURE OF INFORMATION</h3>
          <p className="section-description pl-0">
            We share personal information in the following circumstances or as otherwise described in this policy:
          </p>
          <p className="section-description pl-0">
            <ul>
              <li>We disclose personal information with service providers that access personal information to
                perform work for us, such as companies that assist us with web hosting, fraud prevention, and
                customer service.
              </li>
              <li>We may disclose personal information if we believe that disclosure is in accordance with, or
                required by, any applicable law or legal process, including lawful requests by public authorities to
                meet national security or law enforcement requirements.
              </li>
              <li>We may disclose personal information if we believe that your actions are inconsistent with our
                user agreements or policies, if we believe that you have violated the law, or if we believe it is
                necessary to protect the rights, property, and safety of Terr.ai, our users, the public, or others.
              </li>
              <li>We disclose personal information to our lawyers and other professional advisors where necessary
                to obtain advice or otherwise protect and manage our business interests.
              </li>
              <li>We may disclose personal information in connection with, or during negotiations concerning, any
                merger, sale of company assets, financing, or acquisition of all or a portion of our business by
                another company.
              </li>
              <li>Personal information is disclosed between and among Terr.ai and our current and future parents,
                affiliates, and subsidiaries and other companies under common control and ownership.
              </li>
              <li>We disclose personal information with your consent or at your direction.
                We also share aggregated or de-identified information that cannot reasonably be used to identify you.
              </li>
            </ul>
          </p>
          <h3 className="section-title">ANALYTICS</h3>
          <p className="section-description pl-0">
            We engage others to provide analytics services on our website or relating to our Services. These entities
            may use cookies, web beacons, device identifiers, and other technologies to collect information about your use
            of our Services and other websites and applications, including your IP address, web browser, pages viewed,
            time spent on pages, links clicked, and conversion information. This information may be used by Terr.ai and
            others to, among other things, analyze and track data, improve our Services, determine the popularity of
            certain content, and better understand your online activity.
          </p>
          <h3 className="section-title">YOUR CHOICES</h3>
          <p className="section-description pl-0">
            <b>Account Information</b>
          </p>
          <p className="section-description pl-0">
            If you wish to deactivate your account,
            please email us at hello@terr.ai, but note that we may retain certain information as
            required by law or for our legitimate business purposes.
          </p>
          <p className="section-description pl-0">
            <b>Cookies</b>
          </p>
          <p className="section-description pl-0">
            Most web browsers are set to accept cookies by default. If you prefer, you can usually adjust your
            browser settings to remove or reject browser cookies. Please note that removing or rejecting cookies
            could affect the availability and functionality of our Services.
          </p>
          <h3 className="section-title">CONTACT US</h3>
          <p className="section-description pl-0">
            If you have any questions about this Privacy Policy, please contact us at<a className="pl-5" href="mailto:hello@terr.ai">hello@terr.ai</a>.
          </p>
        </main>
      </IonContent>
    </IonPage>
  );
};

export default PrivacyPolicy;
