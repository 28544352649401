import { environment } from '@env';
import {
  OAuthResponse,
  Session,
  SignInWithOAuthCredentials,
  SignInWithPasswordCredentials,
  SignUpWithPasswordCredentials,
  User,
} from '@supabase/supabase-js';
import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

import { useLocation } from 'react-router-dom';
import { supabase } from '../utils/supabaseClient';

type AuthContextType = {
  signInWithPassword: (credentials: SignInWithPasswordCredentials) => Promise<AuthResponse>;
  signInWithOAuth: (credentials: SignInWithOAuthCredentials) => Promise<OAuthResponse>;
  signInAnonymously: () => Promise<AuthResponse>;
  signUp: (credentials: SignUpWithPasswordCredentials) => Promise<AuthResponse>;
  signOut: () => Promise<void>;
  user: User | null;
  session: Session | null;
  shouldHaveAuthToken: boolean;
  isAuthenticated: boolean;
};

type AuthResponse = {
  data: {
    user: User | null;
    session: Session | null;
  };
  error: Error | null;
};

const AuthContext = createContext<AuthContextType | undefined>(undefined);

const AuthProvider = ({ children }: { children: ReactNode }) => {
  const [session, setSession] = useState<Session | null>(null);
  const location = useLocation();

  const authTokenStorageKey = useMemo(() => {
    const projectId = environment.supabaseUrl.replace(/^https?:\/\//, '').split('.')[0];

    if (!projectId) throw new Error('Missing supabaseUrl config.');

    return `sb-${projectId}-auth-token`;
  }, []);

  // Haing Auth Token means that user is most likely logged in to an existing account,
  // but we might not yet have fetched their user details, so we use hasAuthToken to keep
  // track for edge cases.
  const [hasAuthToken, setHasAuthToken] = useState<boolean>(
    !!localStorage.getItem(authTokenStorageKey)
  );

  // If we're being redirected from OAuth, we get access_token in the location hash,
  // but there's a tick delay before it gets processed and stored as Auth Token in Local Storage.
  // At the same time, on the next tick, the access_token hash will disappear.
  // But we normally set hasAuthToken after auth state changes, but setState has a tick delay,
  // so there'll be a single tick when the Auth Token is present, but it's not yet reflected in
  // React state.
  // To handle that we set the flag already to true.
  useEffect(() => {
    if (location.hash.includes('access_token')) setHasAuthToken(true);
  }, [location.hash]);

  const shouldHaveAuthToken = useMemo(() => {
    return hasAuthToken || location.hash.includes('access_token');
  }, [hasAuthToken, location]);

  const user = useMemo(() => {
    return session?.user ?? null;
  }, [session]);

  const isAuthenticated = useMemo(() => !!user, [user]);

  useEffect(() => {
    const {
      data: { subscription },
    } = supabase.auth.onAuthStateChange((_event, session) => {
      setSession(session);
      setHasAuthToken(!!session);
    });

    return () => subscription.unsubscribe();
  }, [authTokenStorageKey]);

  const signInWithPassword = useCallback(
    async (credentials: SignInWithPasswordCredentials): Promise<AuthResponse> => {
      if (user?.is_anonymous) {
        await supabase.auth.updateUser(credentials);
      }

      return supabase.auth.signInWithPassword(credentials);
    },
    []
  );

  const signInWithOAuth = useCallback(
    async (credentials: SignInWithOAuthCredentials) => {
      if (user?.is_anonymous) return supabase.auth.linkIdentity(credentials);

      return supabase.auth.signInWithOAuth(credentials);
    },
    [user]
  );

  const signInAnonymously = useCallback(async () => {
    return supabase.auth.signInAnonymously();
  }, []);

  const signUp = useCallback(
    async (credentials: SignUpWithPasswordCredentials): Promise<AuthResponse> => {
      if (user?.is_anonymous) {
        await supabase.auth.updateUser(credentials);
        return supabase.auth.signInWithPassword(credentials);
      }

      return supabase.auth.signUp(credentials);
    },
    []
  );

  const signOut = useCallback(async () => {
    setSession(null);
    await supabase.auth.signOut();
  }, []);

  return (
    <AuthContext.Provider
      value={{
        signInWithOAuth,
        signInWithPassword,
        signInAnonymously,
        signUp,
        signOut,
        shouldHaveAuthToken,
        user,
        session,
        isAuthenticated,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);

  if (context === undefined) {
    throw new Error('useAuth must be used within an AuthProvider');
  }

  return context;
};

export default AuthProvider;
